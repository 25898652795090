<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入商品名" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="showModel=true">添加虚拟评论</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="name"
          label="用户信息"
          width="200">
        <template slot-scope="scope">
          <div style="display: flex">
            <span><el-avatar shape="square" :size="60" :src="scope.row.avatar" class="mg-rt-5"></el-avatar></span>
            <span style="margin-top: 20px">{{scope.row.name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_name"
          label="商品名称"
          width="260">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <span><el-avatar shape="square" :size="60" :src="scope.row.goods_img" class="mg-rt-5"></el-avatar></span>
            <span>{{scope.row.goods_name}}<span v-if="scope.row.specs" style="color: #409EFF;"> / {{scope.row.specs}}</span></span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="goods_score"
          label="商品分数"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="service_score"
          label="服务分数"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="imgs"
          label="图片"
          align="left"
          width="220">
        <template slot-scope="scope">
          <viewer :images="scope.row.imgs">
            <img v-for="(item,index) in scope.row.imgs" :src="item" :key="index" width="60" height="60" class="mg-rt-5">
          </viewer>
<!--          <el-avatar shape="square" :size="60" :src="item" v-for="(item,index) in scope.row.imgs" :key="index" class="mg-rt-5"></el-avatar>-->
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="审核状态"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-switch :value="scope.row.shenhe_status==1" @change="statusGroup(scope.row.id,scope.row.shenhe_status==1?0:1)" active-text="关闭" inactive-text="开启">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column
          prop="content"
          label="内容"
          min-width="200"
          align="left">
      </el-table-column>
      <el-table-column
          prop="evaluate_time"
          label="评价时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="status_str"
          label="回复状态"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="reply_content"
          label="回复内容"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          align="center"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="showReplyModel=true; replyInfo.id = scope.row.id;">回复</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog title="添加评论" :visible.sync="showModel" custom-class="dialog" width="940px" :append-to-body="true">
      <virtual-review :callBack="addOk"></virtual-review>
    </el-dialog>
    <el-dialog title="回复" :visible.sync="showReplyModel" custom-class="dialog" width="540px" :append-to-body="true">
      <el-input type="textarea" v-model="replyInfo.content" rows="3" placeholder="请输入内容"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showReplyModel = false" size="small">取 消</el-button>
        <el-button type="primary" @click="reply" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

import virtualReview from "@/components/Goods/VirtualReview";
export default {
  components: {
    virtualReview
  },
  data() {
    return {
      showModel: false,
      showReplyModel: false,
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
      replyInfo: {
        id: '',
        content: ''
      }
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords
      }
      this.$api.goods.evaluateIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    statusGroup(id,status) {
      this.$api.goods.evaluateShenhe({id: id, status: status},res => {
        if(res.errcode == 0) {
          this.success('操作成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.goods.evaluateDel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    reply() {
      this.$api.goods.evaluateReply({id: this.replyInfo.id,content: this.replyInfo.content}, res => {
        if (res.errcode == 0) {
          this.showReplyModel = false
          this.success('回复成功')
          this.getList()
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    addOk() {
      this.showModel = false
      this.getList()
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
