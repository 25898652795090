<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="商品" prop="goods">
        <el-button type="primary" @click="showModel=true">选择商品</el-button>
        <div class="goods-box" v-if="info.goods_id">
          <div class="item">
            <img class="img" :src="info.goods_img">
            <div class="name">{{info.goods_name}}</div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="用户姓名" prop="name">
        <el-input v-model="info.name" placeholder="请输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="用户头像" prop="avatar">
        <SelectImgs :selectNum="1" :selectData="[info.avatar]" :selectFunc="imgChoose" :idx="1"></SelectImgs>
      </el-form-item>
      <el-form-item label="评论内容" prop="content">
        <el-input type="textarea" v-model="info.content" placeholder="请输入内容"></el-input>
      </el-form-item>
      <el-form-item label="商品分数">
        <el-input-number v-model="info.goods_score" :min="0" :max="10" label="请输入分数"></el-input-number>
      </el-form-item>
      <el-form-item label="服务分数">
        <el-input-number v-model="info.service_score" :min="0" :max="10" label="请输入分数"></el-input-number>
      </el-form-item>
      <el-form-item label="评论图" prop="imgs">
        <SelectImgs :selectNum="5" :selectData="info.imgs" :selectFunc="imgChoose" :deleteFunc="deleteFunc" :idx="2"></SelectImgs>
        <span style="color: #ccc;">最多5张图，第一张图为默认图</span>
      </el-form-item>
      <el-form-item label="评论时间">
        <el-date-picker v-model="info.evaluate_time" type="datetime" placeholder="选择日期时间"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="cancel()">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="选择商品" :visible.sync="showModel" custom-class="dialog" width="1000px" :append-to-body="true">
      <goods-list :type="1" :selectFunc="selectGoods"></goods-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from "@/components/SelectImgs";
import GoodsList from "@/components/Goods/GoodsList";
export default {
  components: {
    SelectImgs,
    GoodsList
  },
  props: {
    callBack: Function
  },
  data() {
    return {
      showModel: false,
      info: {
        id: 0,
        name: '',
        avatar: '',
        content: '',
        goods_score: '',
        service_score: '',
        imgs: [],
        evaluate_time: '',
        goods_id: '',
        goods_name: '',
        goods_img: ''
      },
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        name: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' }
        ],
        avatar: [
          { required: true, message: '请选择头像', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入评论内容', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    selectGoods(item) {
      this.info.goods_id = item.id
      this.info.goods_name = item.title
      this.info.goods_img = item.img
      this.showModel = false
    },
    imgChoose(item,index) {
      var _this = this
      if(index==1) {
        _this.info.avatar = item.pic
      } else {
        var imgs = this.info.imgs
        item.forEach(function (child) {
          if (_this.info.imgs.length < 5) {
            imgs.push(child.pic)
          }
        })
        _this.info.imgs = imgs
      }
    },
    deleteFunc(idx) {
      var imgs = JSON.parse(JSON.stringify(this.info.imgs))
      imgs.splice(idx, 1)
      this.info.imgs = imgs
    },
    cancel() {
      if(this.callBack) {
        this.callBack()
      }
    },
    submitForm(formName) {
      var _this = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api.goods.evaluateEdit(_this.info,res => {
            if(res.errcode == 0) {
              _this.success(res.errmsg)
              _this.cancel()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
</style>
